.controlContainer {
  position: relative;
  min-height: 72px;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(0, 1fr) auto auto;
  border-radius: 6px;
  background-color: var(--color-primary-shade-10);

  &Error {
    background-color: var(--color-warning-shade-20);
  }
}

.inputContainer {
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 14px 0 14px 16px;
}

.inputLabel {
  font: var(--font-h14-sm);
  text-align: left;
  color: var(--color-primary-light);
}

.inputHintMessageBox {
  --tt-arrow-height: 6px;

  z-index: 1;
  position: absolute;
  top: calc(100% + var(--tt-arrow-height));
  left: 0;
  width: 100%;
  background: var(--color-primary-light);
  padding: 16px;
  border-radius: 6px;
  font: var(--font-h11);
  color: var(--color-foreground-contrast-2);

  &::before {
    content: '';
    background: inherit;
    z-index: -1;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: center;
    left: calc(var(--tt-arrow-height) * 3);
    top: calc(-1 * var(--tt-arrow-height) / 1.414); // sqrt(2)
    height: calc(var(--tt-arrow-height) * 2 / 1.414); // sqrt(2)
    width: calc(var(--tt-arrow-height) * 2 / 1.414); // sqrt(2)
  }
}

.inputHintInnerContainer {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 16px;
}

.inputHintInnerLockIcon {
  height: 20px;
  width: 20px;
  color: var(--color-primary);
}

.inputHintInnerContent {
  display: grid;
  gap: 4px;
}

.input {
  color: var(--color-foreground);
  font-weight: 500;
  font-family: inherit;
  font-size: 22px;
  line-height: 1;
  height: 22px;
  padding: 0;
  outline: none;
  border: none;
  background: none;

  &Loading {
    visibility: hidden;
  }
}

.inputLoader {
  position: absolute;
  top: 36px;
  left: 14px;
}

.roundProgressContainer {
  display: grid;
  place-items: center;
  height: 100%;
  padding-left: 4px;
  padding-right: 12px;
  background-color: inherit;
}

.dropdownButton {
  --dd-btn-bg: var(--color-primary-dark);

  width: 132px;
  cursor: pointer;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: var(--dd-btn-bg);
  color: var(--color-foreground);
  padding: 14px 16px;

  &:hover {
    --dd-btn-bg: var(--color-primary-dimmed);
  }
}

.dropdownButtonInner {
  width: 100%;
  height: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 2px;
  align-items: center;
  text-align: left;

  > svg {
    pointer-events: none;
    width: 100%;
  }
}

.dropdownButtonInnerCurrencyContainer {
  display: grid;
  gap: 4px;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 12px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--dd-btn-bg));
  }
}

.dropdownButtonInnerCurrencyTicker {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
}

.dropdownButtonInnerCurrencyNetwork {
  text-transform: uppercase;
  font: var(--font-blockchain);
  font-weight: 500;

  width: fit-content;
  padding: 3px;
  border-radius: 4px;
}

.dropdownMenu {
  background: var(--color-background-contrast);
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: grid;
  outline: none;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--border-color);
}

.dropdownMenuHead {
  display: grid;
  grid-template-columns: 24px 1fr 48px;
  align-items: center;
  padding: 14px 0 16px 14px;
  border-bottom: 1px solid var(--border-color);
}

.dropdownMenuHeadSearchIcon {
  width: 24px;
  height: 24px;
  color: var(--color-primary);
  margin: 4px;
}

.dropdownHeadSearchInput {
  margin-left: 16px;
  padding: 0;
  outline: none;
  border: none;
  font: var(--font-h9);
}

.dropdownHeadCloseButton {
  cursor: pointer;
  background: none;
  color: var(--color-primary);
  transform: rotate(180deg);
  border: none;
  padding: 0;
  margin-left: -2px;
  height: 40px;
}

.dropdownGroupTitle {
  padding: 14px 16px 4px;
  font: var(--font-h13);
  color: var(--light-neutral-text-color);
}

.dropdownItemsContainer {
  max-height: 256px;
  overflow-y: scroll;
  overscroll-behavior: none;
}

.dropdownItem {
  min-height: 60px;
  padding: 14px 16px;

  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  gap: 12px;
  font: var(--font-h9-md);
  font-weight: 500;
  line-height: 1em;
  color: var(--light-neutral-text-color);

  cursor: pointer;
}

.dropdownItem[data-focus],
.dropdownItem:hover {
  background: var(--color-primary-alpha-20);
}

.dropdownItem[aria-selected='true'] {
  background: var(--color-primary-light);
}

.dropdownItemCurrencyIcon {
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-round);
  background: var(--color-primary);
  color: var(--color-foreground);
}

.dropdownItemCurrencyLabelContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 6px;
  row-gap: 4px;
}

.dropdownItemCurrencyTicker {
  font-size: 16px;
}

.dropdownItemCurrencyName {
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
}

.dropdownItemFiatCurrencySymbolIcon {
  text-align: center;
  font-size: 14px;
  line-height: 100%;
  transform: translateY(4%);
  white-space: nowrap;
}

.dropdownNoCurrenciesFoundPlaceholder {
  display: grid;
  text-align: center;
  place-items: center;
  padding: 32px 16px;
  font: var(--font-h9);
  color: var(--light-neutral-text-color);
}
