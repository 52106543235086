@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.inputLoader {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  aspect-ratio: 1;
  animation: 1s linear spin infinite reverse;
}
