.buttonLink, .buttonLink:hover {
  display: inline-block;
  text-decoration: none;
  max-width: 100%;
  width: fit-content;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  max-width: 100%;
  font: var(--font-button);
  text-align: center;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  text-transform: uppercase;
  text-decoration: none;
  text-wrap: nowrap;
  transition: color, border-color, opacity, var(--transition-rapid);

  svg path {
    transition: fill ease-in-out 0.1s;
  }

  &:hover {
    text-decoration: none;

    svg path {
      transition: fill ease-in-out 0.2s;
    }
  }

  &[disabled] {
    pointer-events: none;
  }
}

.primary {
  color: var(--primary-button-dark-color);
  background-color: var(--primary-button-bg-dark-color);

  &.normal {
    padding: 17px 50px 15px;
  }

  &[disabled] {
    opacity: 0.3;
  }

  svg path {
    fill: var(--primary-button-dark-color);
  }

  &.active, &:hover {
    color: var(--primary-button-hover-dark-color);
    background-color: var(--primary-button-bg-hover-dark-color);

    svg path {
      fill: var(--primary-button-hover-dark-color);
    }
  }
}

.secondary {
  color: var(--secondary-button-dark-color);
  background-color: var(--secondary-button-bg-dark-color);

  &.normal {
    padding: 15px 50px;
  }

  &[disabled] {
    opacity: 0.2;
  }

  svg path {
    fill: var(--secondary-button-dark-color);
  }

  &.active, &:hover {
    color: var(--secondary-button-hover-dark-color);
    background-color: var(--secondary-button-bg-hover-dark-color);

    svg path {
      fill: var(--secondary-button-hover-dark-color);
    }
  }
}

.stroke {
  font: var(--font-button-stroke);
  color: var(--stroke-button-dark-color);
  background-color: transparent;
  border: 1px solid var(--stroke-button-dark-color);

  &.normal {
    padding: 12px 50px;
  }

  &[disabled] {
    opacity: 0.3;
  }

  svg path {
    fill: var(--stroke-button-dark-color);
  }

  &.active, &:hover {
    color: var(--stroke-button-hover-dark-color);
    border: 1px solid var(--stroke-button-hover-dark-color);

    svg path {
      fill: var(--stroke-button-hover-dark-color);
    }
  }
}

.neutral {
  color: var(--neutral-button-dark-color);
  background-color: var(--neutral-button-bg-dark-color);

  &.normal {
    padding: 12px 50px;
  }

  &[disabled] {
    opacity: 0.5;
  }

  svg path {
    fill: var(--neutral-button-bg-hover-dark-color);
  }

  &.active, &:hover {
    background-color: var(--neutral-button-bg-hover-dark-color);

    svg path {
      fill: var(--neutral-button-dark-color);
    }
  }
}

.primary, .secondary, .stroke, .neutral {
  &.small {
    padding: 5px 19px;
    font: var(--font-button-small);
    text-transform: unset;
    border-radius: 80px;
  }

  &.circle {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 100%;
  }

  &.square {
    width: 48px;
    height: 48px;
    padding: 10px;
    border-radius: 5px;
  }
}

.tab, .leftTab, .rightTab {
  font: var(--font-button-small);
  text-transform: unset;
  color: var(--special-dark-color);
  background-color: var(--neutral-button-bg-dark-color);
  border-radius: 50px;

  &.normal, &.small {
    padding: 8px 20px;
  }

  &[disabled] {
    opacity: 0.3;
  }

  &.active, &:hover {
    color: var(--neutral-button-dark-color);
    background-color: var(--neutral-button-bg-hover-dark-color);
  }
}

.leftTab {
  border-radius: 50px 0 0 50px;
}

.rightTab {
  border-radius: 0 50px 50px 0;
}
