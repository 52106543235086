$color: var(--secondary-text-color);

.badge {
  background: var(--default-currency-badge-color);
  color: $color;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
  font-weight: 500;
  max-width: fit-content;
  min-height: 18px;
}

.noValue {
  visibility: hidden;
}

.fiat {
  background: transparent;
  padding: 3px 0.5px;
  color: $color;
}

.chain {
  &_erc20,
  &_eth {
    background: #40a8c7;
  }

  &_bep20 {
    background: #ffc230;
  }

  &_sol {
    background: #f16200;
  }

  &_trc20,
  &_tron {
    background: #e22c2e;
  }
}
