.tabList {
  border-radius: 4px;
  padding: 4px;
  background: var(--color-primary-alpha-10);
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
}

.tab {
  font: inherit;
  font-size: 14px;
  padding: 10px 4px;
  border: 0;

  color: var(--special-dark-color);
  background-color: var(--color-primary-alpha-10);

  text-transform: capitalize;
  cursor: pointer;
  transition: background-color var(--transition-rapid);

  &:hover {
    background-color: var(--color-primary-alpha-20);
  }

  &:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &:last-child {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &:not(:first-child):not(:last-child) {
    border-left: 1px solid var(--color-primary-alpha-20);
    border-right: 1px solid var(--color-primary-alpha-20);
  }

  font-weight: 500;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
}

.tab[aria-selected='true'] {
  color: var(--color-foreground);
  background: var(--color-primary);
}
