.calculatorContainer {
  position: relative;
  padding: 20px 16px;
  width: 100%;
  max-width: var(--calculator-max-width);

  background: var(--secondary-bg-dark-color);

  border-radius: 16px;

  &::before {
    content: '';
    top: -1px;
    left: -1px;
    border-radius: inherit;
    position: absolute;
    z-index: -1;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    // todo: replace this gradient with variables.css gradients in future:
    background: linear-gradient(
      135.7deg,
      var(--bleu-de-france) 0.74%,
      rgba(47, 146, 246, 0.5) 101.76%
    );
  }

  &::after {
    content: url(/static/images/bg-calculator.svg);
    position: absolute;
    top: 6.125%;
    left: 0;
    transform: rotate(-16deg) scale(1.5);
    width: 100%;
    height: auto;
    z-index: -1;
  }
}

.separator {
  margin-top: 24px;
}
