.wrapper {
  position: relative;
  width: 100%;
  height: 18px;

  .filledStar, .emptyStar {
    object-position: center;
    object-fit: contain;
  }

  .filledStar {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
