@use 'src/css/breakpoint.scss' as *;

.wrap,
.partners {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.wrap {
  justify-content: center;
}

.partners {
  justify-content: flex-start;

  @include maxMediaWidth(lg) {
    width: unset;
  }
}

.partner {
  display: grid;
  position: relative;
  max-width: 140px;
  width: 100%;
  height: 42px;

  .partnerLogoWrapper {
    position: relative;
    width: 100%;
    height: 17px;

    .partnerLogo {
      object-position: left;
      object-fit: contain;
    }
  }

  .ratingWrapper {
    display: flex;
    align-items: center;

    .ratingValue {
      padding-left: 10px;
      font: var(--font-h9);
      color: var(--secondary-text-color);
      white-space: nowrap;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @include maxMediaWidth(lg) {
    min-width: 140px;
  }
}

@include maxMediaWidth(lg) {
  .wrap.centered {
    justify-content: center;
  }
}

@include maxMediaWidth(sm) {
  .wrap {
    flex-direction: column;
    gap: 20px;
  }

  .partners {
    justify-content: center;
    gap: 15px;
  }
}

.linksSeparator {
  display: none;
  flex-shrink: 0;
  opacity: 0.3;
  height: 52px;
  width: 1px;
  background-color: var(--color-primary-alpha-20);

  &.outerSeparator {
    display: block;

    @include maxMediaWidth(sm) {
      display: none;
    }
  }

  &.show {
    display: block;
  }
}
